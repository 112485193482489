import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Image,
    Text,
    Badge,
    Flex,
    WrapItem,
    Wrap,
    HStack,
    SimpleGrid,
} from "@chakra-ui/react";
import { Markup } from "interweave";
import { Link } from "gatsby";


const CollectionSummary = (props) => {
    const {
        articles,
        group,
        value,
    } = props;

    console.log(articles)


    return (
        <Link to={`/journals/biology/collections/${value}`}>

            <Box
                columns={2}
                width="sm"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                boxShadow="md"
                height="md"
                align="top"
                _hover={{ border: "1px solid" }}
                backgroundColor="white"
            >
                <Text p={2}>Collection: {group}</Text>
                <SimpleGrid columns={2}>
                {articles.map(article =>
                        (<Box borderRadius="lg" m={6} borderWidth="1px">
                            <Image
                    src={`/static/thumbnails/${article}`}
                    height={150}
                    overflow="hidden"
                    fit="cover"
                    width="100%"
                    top="0"
                /></Box>))}
            </SimpleGrid>
            </Box>
        </Link>
    );
};

CollectionSummary.propTypes = {
   articles: PropTypes.array.isRequired,
};

CollectionSummary.defaultProps = {

};

export default CollectionSummary;
