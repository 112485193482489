import React from "react";

import CollectionsList from "../ui/articleList/CollectionsList";
import Layout from "../components/Layout";

const collectionsTemplate = (props) => {
    const { pageContext } = props;

    const {
        collectionSamples,
        groups,
    } = pageContext;

    const collectionsList = (
        <CollectionsList groups={groups} articles={collectionSamples} />
    );

    return <Layout children={collectionsList} />;
};

export default collectionsTemplate;
