import  React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Wrap,
    WrapItem,
    Button,
    Stack,
    Heading,
    Image,
    HStack,
    Flex,
    Center,
    Tooltip,
    Box,
    Badge,
    Container,
    Text,
    TabPanel,
    Tabs,
    Tab,
    TabList,
    List,
    TabPanels,
    ListItem,
    Icon,
} from "@chakra-ui/react";
import { BsGrid3X2, BsList } from "react-icons/bs";
import { Link } from "gatsby";

import CollectionSummary from "./CollectionSummary";

const CollectionsList = (props) => {
    const {
        groups,
        articles,
    } = props;

    return (
        <Center>
            <Stack
                borderWidth="1px"
                maxWidth="7xl"
                borderRadius="lg"
                p="2em"
            >
                    <Breadcrumb>
                            <BreadcrumbItem>
                                <BreadcrumbLink as={Link} to='/journals/biology'>
                                    Biology
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Collections</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                <HStack>
                        <Heading size="lg" color="gray">
                            Collections
                        </Heading>
                </HStack>
                            <Wrap>
                                {groups.map((group, index) => {
                                    const {
                                       label, value
                                    } = group;

                                    return (
                                        <WrapItem key={`collections-summary-${index}`}>
                                            <CollectionSummary
                                                group={label}
                                                value={value}
                                                articles={articles[value]}
                                            />
                                        </WrapItem>
                                    );
                                })}
                            </Wrap>
            </Stack>
        </Center>
    );
};

CollectionsList.propTypes = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            authors: PropTypes.arrayOf(
                PropTypes.shape({
                    firstName: PropTypes.string,
                    lastName: PropTypes.string,
                })
            ),
            categories: PropTypes.arrayOf(PropTypes.string),
            doi: PropTypes.string,
            figure: PropTypes.shape({ url: PropTypes.string }),
            pdfUrl: PropTypes.string,
            publishedDate: PropTypes.string,
            reviewedBy: PropTypes.string,
            title: PropTypes.string,
        })
    ),
};

CollectionsList.defaultProps = {
    articles: [],
};

export default CollectionsList;
